import React, { useState } from 'react';
import { TextInput, Button } from 'diginet-core-ui/components';
import Config from '../../../../config/index';

const W09F2282Popover = (props) => {
    const { type, item, onSave } = props;
    const [notes, setNotes] = useState('');

    const onSavePopover = () => {
        if (!item || !item.VoucherID) {
            Config.popup.show('INFO', Config.lang("Khong_the_duyet"));
            return false;
        }
        const user = Config.getUser({ UserID: item?.CreateUserID});
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: 'W09F2282',
            EmployeeID: user?.EmployeeID ?? "",
            VoucherID: item?.VoucherID ?? "",
            ApprovalLevel: item?.ApprovalLevel ?? "",
            ApprovalFlowID: item?.ApprovalFlowID ?? "",
            ApprovalStatus: type === 'approve' ? 1 : 100,
            ApprovalNotesU: notes,
            AttendanceDate: item?.AttendanceDate ?? "",
        };
        onSave(params);
    }

    const labelButton = type === 'approve' ? 'Duyet' : 'Tu_choi_W75';
    const ColorButton = type === 'approve' ? 'primary' : 'danger';
    return (
        <div className="pd10">
            <TextInput
                label={Config.lang('Ghi_chu')}
                multiline
                maxRows={4}
                onChange={e => setNotes(e.target.value)}
                value={notes}
            />
            <Button
                label={Config.lang(`${labelButton}`)}
                viewType="filled"
                color={ColorButton}
                className="pull-right"
                onClick={onSavePopover}
                disabled={type === 'approve' ? false : !notes}
            />
        </div>
    );
}

export default W09F2282Popover;