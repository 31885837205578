
/**
 * @copyright 2021 @ DigiNet
 * @author TRANGHOANG
 * @create 10/09/2021
 * @Example
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2270Actions from "../../../../redux/W0X/W09F2270/W09F2270_actions";

import _ from "lodash";
import { Col, FormGroup, Row } from "react-bootstrap";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";

import {Button, Dropdown, DateRangePicker, Avatar, ButtonIcon, Typography, Tooltip} from 'diginet-core-ui/components';
import { CalendarNew } from 'diginet-core-ui/icons';

import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import GridContainer from "../../../grid-container/grid-container";
import History from "../../../libs/history";
import Status from "../../../common/status/status";
import W09F2271 from '../W09F2271/W09F2271';
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";

const styles = {
    dateInfo: {
        display: "flex",
        alignItems: "center",
    },
    dateRangeClass: {
        '& input': {
            padding: '10px !important'
        }
    },
    VoucherNo: {
        maxWidth: 500,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'left'
    }
};

const W09F2270 = (props) => {
    const { dataGrid, getCboAppStatus, classes, location } = props;
    const FormID = "W09F2270";
    const Language = Config.language || "84";
    const DateFrom = null;
    const DateTo = null;


    const [gridLoading, setGridLoading] = useState(false);
    const [cboAppStatusLoading, setCboAppStatusLoading] = useState(false);
    const [cboEmployeeLoading, setCboEmployeeLoading] = useState(false);
    const [iPermission, setIPermission] = useState(0);
    const [showW09F2271Popup, setShowW09F2271Popup] = useState(false);
    const [showW84F3005Popup, setShowW84F3005Popup] = useState(false);
    const [dataPopup, setDataPopup] = useState({
        mode:'',
        rowData: null
    });
    const [filterSearch, setFilterSearch] = useState({
        Employee: "",
        EmployeeID: "",
        AppStatusID: null
    });
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0
    });
    const [rangeDate, setRangeDate] = useState([DateFrom, DateTo]);


    const itemPageDataGrid = useRef({
        limit: 10,
        skip: 0
    });
    const filterCboEmployees = useRef({
        Type: "EmployeeID",
        timer: null,
        HostID: "",
        strSearch: "",
        skip: 0,
        limit: 50
    });
    const filterRangePicker = useRef({
        DateFrom,
        DateTo,
    });

    useEffect(() => {
        loadPermission();
        if (!iPermission) return;
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    /**
     * open W09F2271Popup from notify
     */
    useEffect(() => {
        if (location && location.state?.voucher_id) {
            showPopup('view', {VoucherID: location.state?.voucher_id});
        }
        /* eslint-disable */
    }, [location]);

    /**
     * load data Grid
     */
    const loadGrid = () => {
        const { AppStatusID, EmployeeID } = filterSearch;
        const { DateFrom, DateTo } = filterRangePicker.current;
        const { skip, limit } = itemPageDataGrid.current;
        const params = {
            FormID,
            DateFrom,
            DateTo,
            EmployeeID,
            AppStatusID,
            Language,
            skip,
            limit,
        };
        setGridLoading(true);
        props.w51F1100Actions.loadGrid(params, (error) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error || Config.lang("Loi_chua_xac_dinh"));
                return false;
            }
        });
    };

    /**
     * load data combobox Employee in Dropdown Filter
     * @param isReset {boolean} - true: reset data, false: concat data
     */
    const loadCboEmployees = (isReset) => {
        const { HostID, Type, skip, limit, strSearch: search } = filterCboEmployees.current;
        const param = {
            HostID,
            Type,
            FormID,
            Language,
            skip,
            limit,
            search
        };
        setCboEmployeeLoading(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
            setCboEmployeeLoading(false);
            if (error) {
                Config.popup.show("ERROR", error || Config.lang("Loi_chua_xac_dinh"));
                return false;
            }
            else if (data) {
                const rows = data?.rows ? data.rows : data;
                const total = data?.total ? data.total : data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total
                })
            }
        });
    };

    /**
     * load data combobox status in Dropdown Filter
     */
    const loadCboAppStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language,
        };
        setCboAppStatusLoading(true);
        props.generalActions.getCboAppStatus(params, (err) => {
            setCboAppStatusLoading(false);
            if (err) {
                Config.popup.show("INFO", _.get(err, "err.message", Config.lang("Loi_chua_xac_dinh")));
            }
        });
    };

    /**
     *
     * @type {Function}
     */
    const loadPermission = useCallback(() => {
        props.generalActions.getPermission("W09F2270", (isPer) => {
            setIPermission(isPer);
        });
    }, [props.generalActions]);

    /**
     * open popup W09F2271
     * @param mode
     * @param rowData
     */
    const showPopup = (mode, rowData) => {
        setDataPopup({
            mode,
            rowData
        });
        if(mode === 'history') setShowW84F3005Popup(true);
        else setShowW09F2271Popup(true);
    };

    /**
     * close popup W09F2271
     * @param reload
     */
    const onCloseModal = (reload = false) => {
        if(reload) loadGrid();
        setShowW09F2271Popup(false);
        setShowW84F3005Popup(false);
        setDataPopup({
            mode:'',
            rowData: null
        });
    };

    /**
     * show popup "YES/NO" confirm delete row
     * @param e - row data
     * @returns {Promise<void>}
     */
    const onDelete = (e) => {
        const { data } = e;
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), async () => {
            const params = {
                FormID,
                Language,
                VoucherID: _.get(data, "VoucherID", "")
            };
            setGridLoading(true);
            props.w51F1100Actions.deleteRowData(params, async (error) => {
                setGridLoading(false);
                if (error) {
                    let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                else{
                    await saveHistory(data);

                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                    loadGrid();
                }
            });
        });
    };

    /**
     * action save history when add or delete success
     * @param data - data to save
     * @returns {Promise<boolean>}
     */
    const saveHistory = async (data) => {
        if(!data) return false;
        const { VoucherID, VoucherName, Notes } = data;
        if (VoucherID) {
            // data Content
            const _data = {
                VoucherName,
                Notes
            };

            //name Content
            const captions = {
                VoucherName: "Ten_phieu",
                Notes: "Ghi_chu",
            };

            const options = {
                data:_data,
                captions,
                action: 3, //Delete
                ModuleID: "D09",
                TransactionID: FormID,
                TransID: VoucherID,
                TransactionName: Config.lang("Ky_luat_hang_loat")
            };
            const history = new History(options); //Init history
            await history.save()

        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };

    /**
     * update state FilterSearch when change value of the components in Dropdown Filter
     * @param filterName - Component name
     * @param e - data component return
     */
    const filterChange = (filterName = "", e) => {
        if (!e) return;
        let value = _.get(e, "value", _.get(e, "target.value", ""));
        switch (filterName) {
            case "AppStatusID":
                setFilterSearch({ ...filterSearch, [filterName]: (value || value === 0) ? value : null });
                break;
            case "EmployeeID":
                setFilterSearch({ ...filterSearch, [filterName]: (value || value === 0) ? value : ""});
                break;
            case "RangePicker":
                const DateFrom = _.isArray(value) ? value[0] : value;
                const DateTo = _.isArray(value) ? value[1] : value;
                filterRangePicker.current = {
                    DateFrom,
                    DateTo,
                };
                setRangeDate([DateFrom, DateTo]);
                break;
            default:
                break;
        }
    };

    /**
     * function onload data when open Dropdown Filter
     */
    const onLoadDataFilter = () => {
        loadCboAppStatus();
        loadCboEmployees();
    };

    /**
     * render popup filter on Header
     * @returns {*}
     */
    const renderFilterData = () => {
        return (
            <Filter
                isUseDDCore={true}
                openOnFieldClick={true}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col style={{ marginTop: 10 }} xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            style={{ margin: 0 }}
                                            dataSource={_.get(dataCboEmployees, "rows", [])}
                                            total={_.get(dataCboEmployees, "total", 0)}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            value={filterSearch.EmployeeID}
                                            loading={cboEmployeeLoading}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            keyExpr={"EmployeeName"}
                                            searchDelayTime={1000}
                                            // iconExpr={"UserPictureURL"}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            onChange={(e) => filterChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                filterCboEmployees.current.strSearch = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                    <Col style={{ marginTop: 10 }} xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            style={{ margin: 0 }}
                                            viewType={"outlined"}
                                            valueExpr={"AppStatusID"}
                                            keyExpr={"AppStatusName"}
                                            displayExpr={"AppStatusName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Trang_thai")}
                                            onChange={e => filterChange("AppStatusID", e)}
                                            dataSource={getCboAppStatus}
                                            loading={cboAppStatusLoading}
                                            value={filterSearch.AppStatusID}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.dateRangeClass}>
                                            <DateRangePicker
                                                controls
                                                clearAble
                                                value={rangeDate}
                                                style={{ marginBottom: 0 }}
                                                viewType={"outlined"}
                                                label={Config.lang("Ngay_tao")}
                                                onChange={e => filterChange("RangePicker", e)}
                                                placeholder={"DD/MM/YY - DD/MM/YY"}
                                                returnFormat={"YYYY-MM-DD"}
                                            />
                                        </span>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div style={{ display: "flex", justifyContent: "center", }}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={loadGrid}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    /**
     * render 2 column Info Employee
     * @param e
     * @returns {*}
     */
    const renderEmpProfile = (e) => {
        const { data } = e.row;
        const { CreateDate = "" } = data;
        const user = Config.getUser({UserID:data.CreateUserID});
        return (
            <div className={"display_row align-center pdt10 pdb10"}
                 style={{width:'100%'}}
            >
                <Avatar
                    clearAble={false}
                    width={64}
                    height={64}
                    readOnly
                    data={{
                        [Config.lang("Nhan_vien")]:`${user?.EmployeeID} - ${user?.EmployeeName}`,
                        [Config.lang("Phong_ban")]:user?.DepartmentName,
                        [Config.lang("Ngay_vao_lam")]:Config.convertDate(user?.DateJoined,'','L'),
                    }}
                    hoverAble
                    src={user?.UserPictureURL || ''}
                />
                <div className={'mgl10'}>
                    <Typography type={"h3"}>
                        {user?.EmployeeName}
                    </Typography>
                    <div className={"display_row align-center mgt5"}>
                        <CalendarNew
                            height={20}
                            viewBox
                            width={20}
                        />
                        <Typography type={"p2"}
                                    style={{ paddingLeft: 5 }}
                                    color={Config.coreTheme?.colors?.placeholder}>
                            {Config.convertDate(CreateDate, '', "LT, DD/MM/YYYY A")}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    };

    /**
     * render column VoucherNo (HTML)
     * @param e - row data
     * @returns {*}
     */
    const renderInfo = (e) => {
        const { data } = e.row;
        const content = <div className={classes.VoucherNo} dangerouslySetInnerHTML={{ __html: data.VoucherNo }} />;
        return <Tooltip title={content}>{content}</Tooltip>;
    };

    /**
     * render colum status
     * @param data - row data
     * @returns {*}
     */
    const renderStatus = ({data}) => <Status data={data}/>;

    /**
     * on change Page
     * @param page {number} - new page
     */
    const onChangeInfoPage = (page) => {
        itemPageDataGrid.current.skip = page * itemPageDataGrid.current.limit;
        loadGrid();
    };

    /**
     * action on click change perpage
     * @param perPage {number} new paer page
     */
    const onChangePerPage = (perPage) => {
        itemPageDataGrid.current.skip = 0;
        itemPageDataGrid.current.limit = perPage;
        loadGrid();
    };

    /**
     * render column Action
     * @param e - row data
     * @returns {*}
     */
    const renderAction = (e) => {
        const {data} = e.row;
        return (
            <div className={"display_row align-center"} style={{grep:4}}>
                <ButtonIcon
                    size={"small"}
                    name={"History"}
                    viewType={"text"}
                    viewBox
                    circular
                    disabled={!(iPermission > 0)}
                    onClick={() => showPopup("history", e.row?.data)}
                />
                <ButtonIcon
                    size={"small"}
                    disabled={!(iPermission > 0)}
                    name={"View"}
                    viewType={"text"}
                    viewBox
                    circular
                    onClick={() => showPopup("view", e.row?.data)}
                />
                <ButtonIcon
                    size={"small"}
                    disabled={!(iPermission > 3 && Number(data?.AppStatusID) === 0)}
                    name={"Delete"}
                    viewType={"text"}
                    viewBox
                    circular
                    onClick={() => onDelete(e)}
                />
            </div>
        );
    };

    if (!iPermission) return null;
    return (
        <React.Fragment>
            <div className={"hidden"}>{renderFilterData()}</div>
            <ActionToolbar title={Config.lang("Ky_luat_hang_loat")}>
                <Button
                    disabled={!(iPermission > 1)}
                    size={"medium"}
                    color={"primary"}
                    startIcon={"AddCircle"}
                    viewType={"filled"}
                    label={Config.lang("Them")}
                    style={{textTransform: "uppercase"}}
                    onClick={() => showPopup("add")}
                />
            </ActionToolbar>
            <GridContainer
                itemPerPage={itemPageDataGrid.current.limit}
                skipPerPage={itemPageDataGrid.current.skip}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                hoverStateEnabled={true}
                typePaging={"remote"}
                noDataText={Config.lang("No_data")}
                height={Config.getHeightGrid()}
                style={{ border: 'none' }}
                loading={gridLoading}
                totalItems={_.get(dataGrid, "total", 0)}
                dataSource={_.get(dataGrid, "rows", [])}
                editing={{ texts: { confirmDeleteMessage: "" } }}
                onDbCellClick={(e) => {
                    if(iPermission <= 2) return;
                    showPopup("edit", e?.data)
                }}
                onChangePage={onChangeInfoPage}
                onChangePerPage={onChangePerPage}
            >
                <Column cellRender={renderEmpProfile} width={280}/>
                <Column cellRender={renderInfo} cssClass={classes.wordWrap}/>
                <Column cellRender={renderStatus} alignment={"center"} width={200}/>
                <Column cellRender={renderAction} width={200} />
            </GridContainer>
            {showW09F2271Popup && (
                <W09F2271
                    data={{
                        mode: dataPopup.mode,
                        rowData: dataPopup.rowData,
                    }}
                    iPermission={iPermission}
                    open={showW09F2271Popup}
                    onClose={isReload => onCloseModal(isReload)}
                />
            )}
            {showW84F3005Popup &&
                <W84F3005 open={showW84F3005Popup} onClose={onCloseModal}
                          FormID="W09F2270" TransID={dataPopup.rowData?.VoucherID}/>
            }
        </React.Fragment>
    );
};

export default compose(
    connect((state) => ({
        dataGrid: state.W09F2270.dataGrid,
        getCboAppStatus: state.general.getCboAppStatus,
    }), (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w51F1100Actions: bindActionCreators(W09F2270Actions, dispatch),
    })), withStyles(styles))(W09F2270);
