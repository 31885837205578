/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 13/9/2021
 * @Example
 */

import { Column } from 'devextreme-react/data-grid';
import { Avatar, Button, ButtonIcon, DateRangePicker, Dropdown, Tooltip, Typography } from 'diginet-core-ui/components';
import { CalendarNew } from 'diginet-core-ui/icons';
import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../config';
import * as approvalActions from '../../../../redux/approvals/approvals_actions';
import * as generalActions from '../../../../redux/general/general_actions';
import ApprovalsPopover from '../../../approvals/approvalspopover';
import { MForm } from '../../../common/form-material';
import Status from '../../../common/status/status';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Filter from '../../../filter/filter';
import GridContainer from '../../../grid-container/grid-container';
import W84F3005 from '../../../W8X/W84/W84F3005/W84F3005';
import W09F2271 from '../W09F2271/W09F2271';
import { useStyles } from './styles';

const W09F2272 = (props) => {
    const { location } = props;
    const classes = useStyles();

    const [iPermission, setIPermission] = useState(0);
    const [gridLoading, setGridLoading] = useState(false);
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0,
    });
    const [filter, setFilter] = useState(() => ({
        ApprovalStatus: null,
        EmployeeID: null,
    }));
    const [openW09F2271Popup, setOpenW09F2271Popup] = useState(false);
    const [openW84F3005Popup, setOpenW84F3005Popup] = useState(false);
    const [openApprovals, setOpenApprovals] = useState(false);
    const [approving, setApproving] = useState(false);
    const [errorNote, setErrorNote] = useState('');

    const filterRangePicker = useRef({
        DateFrom: null,
        DateTo: null,
    });
    const itemPageDataGrid = useRef({
        limit: 10,
        skip: 0,
    });
    const dataPopup = useRef({
        mode: '',
        rowData: null,
    });
    const dataApproval = useRef({
        anchorEl: null,
        type: '',
        rowData: null,
    });
    const refApprovalPopover = useRef(null);
    const toolFilter = useRef(null);

    const dispatch = useDispatch();
    const dataGrid = useSelector((state) => state.approvals.getGridApproval);
    const dataCboStatus = useSelector((state) => state.approvals.getCboStatus);

    const paramFilterCboEmployees = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission('W09F2272', (isPer) => {
                setIPermission(isPer);
            })
        );
    };

    const loadGrid = () => {
        const { ApprovalStatus, EmployeeID } = filter;
        const { DateFrom, DateTo } = filterRangePicker.current;
        const { skip, limit } = itemPageDataGrid.current;
        const params = {
            VoucherID: '',
            DivisionID: Config.getDivisionID() ?? '',
            FormID: 'W09F2272',
            Language: Config?.language ?? '84',
            skip: skip ?? 0,
            limit: limit ?? 10,
            UserID: Config?.profile?.UserID ?? '',
            ApprovalStatus: ApprovalStatus ?? null,
            EmployeeID: EmployeeID ?? null,
            DepartmentID: '',
            ProjectID: '',
            TransType01ID: '',
            DutyID: '',
            IsRegisterType: '',
            Type: 0,
            DateFrom: DateFrom ?? null,
            DateTo: DateTo ?? null,
        };
        setGridLoading(true);
        dispatch(
            approvalActions.getGridApprovals(params, (error) => {
                setGridLoading(false);
                if (error) {
                    Config.popup.show('ERROR', error || Config.lang('Loi_chua_xac_dinh'));
                    return false;
                }
            })
        );
    };

    useEffect(() => {
        loadPermission();
        loadGrid();
        loadCboStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataGrid?.rows?.length > 0 && dataCboStatus?.length > 0) {
            dataGrid.rows.forEach((row) => {
                const { ApprovalStatus } = row;
                if (ApprovalStatus) {
                    const status = dataCboStatus.find((i) => i.ApprovalStatus === ApprovalStatus);
                    if (status) {
                        row.ApproverName = status?.AppStatusName ?? '';
                    }
                }
            });
        }
    }, [dataGrid, dataCboStatus]);

    useEffect(() => {
        if (location?.state?.voucher_id) {
            showPopup('view', { VoucherID: location?.state?.voucher_id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const loadCboStatus = () => {
        const params = {
            FormID: 'STANDARD',
            Language: Config.language || '84',
        };
        dispatch(
            approvalActions.getCboStatus(params, (error) => {
                if (error) {
                    Config.popup2.show('ERROR', error);
                    return false;
                }
            })
        );
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W39F3030',
            Language: Config.language || '84',
            ...paramFilterCboEmployees,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup2.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const onLoadCboFilter = () => {
        loadCboEmployees();
    };

    const filterChange = (key, data) => {
        switch (key) {
            case 'ApprovalStatus':
            case 'EmployeeID':
                setFilter({ ...filter, [key]: data?.value ?? null });
                break;
            case 'Date':
                filterRangePicker.current = {
                    DateFrom: data?.value?.[0] ?? null,
                    DateTo: data?.value?.[1] ?? null,
                };
                break;
            default:
                break;
        }
    };

    const renderFilter = () => {
        const { DateFrom, DateTo } = filterRangePicker.current;
        return (
            <Filter
                isUseDDCore
                dropdownProps={{
                    ref: toolFilter,
                }}
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                showHeader={false}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => {
                    return (
                        <>
                            <Dropdown
                                clearAble
                                valueExpr={'ApprovalStatus'}
                                keyExpr={'AppStatusName'}
                                displayExpr={'AppStatusName'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Trang_thai')}
                                onChange={(e) => filterChange('ApprovalStatus', e)}
                                dataSource={dataCboStatus}
                                value={filter?.ApprovalStatus ?? null}
                                viewType={'outlined'}
                            />
                            <Dropdown
                                allowSearch
                                clearAble
                                dataSource={dataCboEmployees.rows}
                                total={dataCboEmployees.total}
                                skip={paramFilterCboEmployees.skip}
                                limit={paramFilterCboEmployees.limit}
                                displayExpr={'{EmployeeID} - {EmployeeName}'}
                                keyExpr={'EmployeeName'}
                                valueExpr={'EmployeeID'}
                                placeholder={Config.lang('Chon')}
                                label={Config.lang('Nhan_vien')}
                                onChange={(e) => filterChange('EmployeeID', e)}
                                value={filter?.EmployeeID ?? null}
                                viewType={'outlined'}
                                loading={loadingCboEmployees}
                                onInput={(e) => {
                                    paramFilterCboEmployees.search = e?.target?.value ?? '';
                                    paramFilterCboEmployees.skip = 0;
                                    loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    paramFilterCboEmployees.skip = e?.skip ?? 0;
                                    paramFilterCboEmployees.limit = e?.limit ?? 10;
                                    loadCboEmployees();
                                }}
                            />
                            <DateRangePicker
                                clearAble
                                controls
                                value={[DateFrom ?? null, DateTo ?? null]}
                                label={Config.lang('Ngay_tao')}
                                onChange={(e) => filterChange('Date', e)}
                                placeholder={`${Config.lang('Tu_ngay')} - ${Config.lang('Den_ngay')}`}
                                returnFormat={'YYYY-MM-DD'}
                                viewType={'outlined'}
                            />
                            <div className={`${classes.flex} ${classes.justifyCenter}`}>
                                <Button
                                    text={Config.lang('Tim_kiem')}
                                    viewType="outlined"
                                    color={'primary'}
                                    onClick={onSearch}
                                    startIcon={'Search'}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const getStatusInfo = (data) => {
        const status = data?.ApprovalStatus ?? 0;
        const color = Number(status) === 1 ? 'success' : Number(status) === 100 ? 'danger' : '';
        let _icon = 'HourGlass';
        switch (Number(status)) {
            case 0:
                _icon = 'HourGlass';
                break;
            case 1:
                _icon = 'Approval';
                break;
            case 4:
            case 100:
                _icon = 'Close';
                break;
            default:
                break;
        }
        return {
            AppStatusColor: color,
            AppStatusID: status,
            AppStatusIcon: _icon,
            AppStatusName: data?.ApproverName ?? Config.lang('DHR_Khong_trang_thai'),
        };
    };

    const renderEmpProfile = (e) => {
        const { data } = e.row;
        const { CreateDate } = data;
        const user = Config.getUser({ UserID: data?.CreateUserID ?? '' });
        return (
            <div className={'display_row align-center pdt10 pdb10'} style={{ width: '100%' }}>
                <Avatar
                    clearAble={false}
                    width={64}
                    height={64}
                    readOnly
                    data={{
                        [Config.lang('Nhan_vien')]: `${user?.EmployeeID} - ${user?.EmployeeName}`,
                        [Config.lang('Phong_ban')]: user?.DepartmentName,
                        [Config.lang('Ngay_vao_lam')]: Config.convertDate(user?.DateJoined, '', 'L'),
                    }}
                    hoverAble
                    src={user?.UserPictureURL ?? ''}
                />
                <div>
                    <Typography type={'h3'}>{user?.EmployeeName ?? ''}</Typography>
                    <div className={'display_row align-center mgt5'}>
                        <CalendarNew height={20} viewBox width={20} />
                        <Typography type={'p2'} style={{ paddingLeft: 5 }} color={Config.coreTheme?.colors?.placeholder}>
                            {CreateDate ? Config.convertDate(CreateDate, '', 'LT, DD/MM/YYYY A') : ''}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    };

    const renderInfo = (e) => {
        const { data } = e.row;
        const purifyText = { __html: DOMPurify.sanitize(data.VoucherNo) };
        const description = data?.VoucherNo ? 
            <Tooltip title={<div dangerouslySetInnerHTML={purifyText} />} textAlign={'left'} >
                <div dangerouslySetInnerHTML={purifyText} />
            </Tooltip>
            : null;
        return <div className={'pdt10 pdb10'}>{description}</div>;
    };

    const renderStatus = ({ data }) => {
        const statusData = getStatusInfo(data);
        return <Status data={statusData} />;
    };

    const renderAction = (e) => {
        const { data } = e.row;
        const ApprovalStatus = Number(data?.ApprovalStatus);
        return (
            <div className={'display_row align-center'} style={{ grap: 2 }}>
                <Tooltip title={Config.lang('Lich_su')}>
                    <ButtonIcon
                        size={'small'}
                        name={'History'}
                        viewType={'text'}
                        viewBox
                        circular
                        disabled={iPermission === 0}
                        onClick={() => showPopup('history', e.row?.data)}
                    />
                </Tooltip>
                {(ApprovalStatus === 1 || ApprovalStatus === 4 || ApprovalStatus === 100) && (
                    <Tooltip title={Config.lang('Bo_duyet')}>
                        <ButtonIcon
                            size={'small'}
                            disabled={iPermission < 4}
                            name={'Deny'}
                            viewType={'text'}
                            viewBox
                            circular
                            onClick={() => {
                                const message = Config.lang('DHR_Ban_co_chac_muon_bo_trang_thai_nay?');
                                Config.popup.show('YES_NO', message, () => {
                                    dataApproval.current = {
                                        anchorEl: null,
                                        type: '',
                                        rowData: data ?? null,
                                    };
                                    cancelApprovals();
                                });
                            }}
                        />
                    </Tooltip>
                )}
                {ApprovalStatus === 0 && (
                    <div className={'display_row align-center'} style={{ grap: 2 }}>
                        <Tooltip title={Config.lang('Duyet')}>
                            <ButtonIcon
                                size={'small'}
                                disabled={iPermission < 4}
                                name={'Approval'}
                                viewType={'text'}
                                viewBox
                                circular
                                onClick={(e) => onOpenApproval('approve', e, data)}
                            />
                        </Tooltip>
                        <Tooltip title={Config.lang('Tu_choi')}>
                            <ButtonIcon
                                size={'small'}
                                disabled={iPermission < 4}
                                name={'Cancel'}
                                viewType={'text'}
                                viewBox
                                circular
                                onClick={(e) => onOpenApproval('cancel', e, data)}
                            />
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    };

    const showPopup = (mode, rowData) => {
        dataPopup.current = {
            mode: mode ?? '',
            rowData: rowData ?? null,
        };
        switch (mode) {
            case 'history':
                setOpenW84F3005Popup(true);
                break;
            case 'view':
                setOpenW09F2271Popup(true);
                break;
            default:
                break;
        }
    };

    const onOpenApproval = (type, e, data) => {
        dataApproval.current = {
            anchorEl: e?.currentTarget ?? null,
            type: type ?? '',
            rowData: data ?? null,
        };
        setOpenApprovals(true);
    };

    const onCloseApproval = () => {
        dataApproval.current = {
            anchorEl: null,
            type: '',
            rowData: null,
        };
        setOpenApprovals(false);
        setErrorNote('');
    };

    useEffect(() => {
        if (openApprovals) {
            if (refApprovalPopover.current) refApprovalPopover.current.onOpenPopover();
        } else {
            onCloseApproval();
        }
    }, [openApprovals]);

    const saveApprovals = (status, approvalNotes) => {
        const { rowData } = dataApproval.current;
        const validate = MForm.isRequired(approvalNotes, 'errorNote');

        if (!rowData?.VoucherID) {
            Config.popup.show('INFO', Config.lang('DHR_Khong_the_duyet'));
            return false;
        }

        if (status === 100) {
            if (validate?.errorNote) {
                setErrorNote(validate?.errorNote ?? '');
                return false;
            }
        }

        const params = {
            DivisionID: Config.getDivisionID() ?? '',
            FormID: 'W09F2272',
            EmployeeID: rowData?.EmployeeID ?? '',
            VoucherID: rowData?.VoucherID ?? '',
            ApprovalLevel: rowData?.ApprovalLevel ?? '',
            ApprovalFlowID: rowData?.ApprovalFlowID ?? '',
            ApprovalStatus: status ?? 1,
            ApprovalNotesU: approvalNotes ?? '',
            AttendanceDate: rowData?.AttendanceDate ?? '',
        };

        setApproving(true);

        dispatch(
            approvalActions.saveApprovals(params, (error, data) => {
                setApproving(false);

                if (error) {
                    let message = Config.lang('DHR_Loi_chua_xac_dinh');
                    switch (error?.code) {
                        case 'F1002E007':
                            message =
                                params.ApprovalStatus === 1 ? Config.lang('DHR_Duyet_khong_thanh_cong') : Config.lang('DHR_Tu_choi_khong_thanh_cong');
                            break;
                        default:
                            break;
                    }
                    Config.popup.show('INFO', message);
                    return false;
                }

                if (data?.Status === 1) {
                    let message =
                        data.Message ??
                        (params.ApprovalStatus === 1 ? Config.lang('DHR_Duyet_khong_thanh_cong') : Config.lang('DHR_Tu_choi_khong_thanh_cong'));

                    if (refApprovalPopover.current) {
                        refApprovalPopover.current.onClosePopover(() => {
                            Config.popup.show('INFO', message);
                        });
                    } else {
                        Config.popup.show('INFO', message);
                    }
                    return false;
                }

                onReset(true);

                if (refApprovalPopover.current) refApprovalPopover.current.onClosePopover();

                Config.notify.show('success', Config.lang('DHR_Thuc_hien_thanh_cong'), 2000);
            })
        );
    };

    const cancelApprovals = () => {
        const { rowData } = dataApproval.current;
        if (!rowData?.VoucherID) {
            Config.popup.show('INFO', Config.lang('DHR_Khong_the_duyet'));
            return false;
        }

        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: 'W09F2272',
            VoucherID: rowData?.VoucherID ?? '',
            EmployeeID: rowData?.EmployeeID ?? '',
            ApprovalLevel: rowData?.ApprovalLevel ?? '',
            ApprovalFlowID: rowData?.ApprovalFlowID ?? '',
            ApprovalStatus: rowData?.ApprovalStatus ?? '',
            AttendanceDate: rowData?.AttendanceDate ?? '',
        };

        setApproving(true);

        dispatch(
            approvalActions.cancelApprovals(params, (error, data) => {
                setApproving(false);

                if (error) {
                    let message = Config.lang('DHR_Loi_chua_xac_dinh');
                    Config.popup.show('INFO', message);
                    return false;
                }

                if (data?.Status === 1) {
                    let message = data.Message || Config.lang('DHR_Cap_tiep_theo_da_duyet._Ban_khong_the_bo_duyet');
                    Config.popup.show('INFO', message);
                    return false;
                }

                onReset(true);

                Config.notify.show('success', Config.lang('DHR_Thuc_hien_thanh_cong'), 2000);
            })
        );
    };

    const onReset = (resetData) => {
        dataApproval.current = {
            anchorEl: null,
            type: '',
            rowData: null,
        };

        setOpenApprovals(false);
        setErrorNote('');

        if (resetData) loadGrid();
    };

    const onSearch = () => {
        loadGrid();

        if (toolFilter.current) toolFilter.current.close();
    };

    const onDbCellClick = (e) => {
        const { data = {} } = e;

        showPopup('view', data);
    };

    const onChangePage = (page) => {
        itemPageDataGrid.current.skip = page * (itemPageDataGrid.current?.limit ?? 10);
        loadGrid();
    };

    const onChangePerPage = (perPage) => {
        itemPageDataGrid.current.skip = 0;
        itemPageDataGrid.current.limit = perPage;
        loadGrid();
    };

    const onCloseModal = (key, reload = false) => {
        dataPopup.current = {
            mode: '',
            rowData: null,
        };
        switch (key) {
            case 'W09F2271':
                setOpenW09F2271Popup(false);
                if (reload) loadGrid();
                break;
            case 'W84F3005':
                setOpenW84F3005Popup(false);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <ActionToolbar title={Config.lang('Duyet_ky_luat_hang_loat')} />
            <Row className={'display_row align-center valign-middle mgt15 mgb15'}>
                <Col xs={12} sm={12} md={6} lg={6}>
                    {renderFilter()}
                </Col>
            </Row>
            <GridContainer
                itemPerPage={itemPageDataGrid.current?.limit ?? 10}
                skipPerPage={itemPageDataGrid.current?.skip ?? 0}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                hoverStateEnabled={true}
                typePaging={'remote'}
                noDataText={Config.lang('No_data')}
                style={{ border: 'none' }}
                loading={gridLoading}
                totalItems={dataGrid?.total ?? 0}
                dataSource={dataGrid?.rows ?? []}
                onDbCellClick={onDbCellClick}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
            >
                <Column cellRender={renderEmpProfile} minWidth={150} />
                <Column cellRender={renderInfo} minWidth={250} cssClass={classes.wordWrap} />
                <Column cellRender={renderStatus} minWidth={100} />
                <Column cellRender={renderAction} minWidth={100} />
            </GridContainer>

            {openW09F2271Popup && (
                <W09F2271 open={openW09F2271Popup} data={dataPopup.current} onClose={(e, isReload) => onCloseModal('W09F2271', isReload)} />
            )}

            {openW84F3005Popup && (
                <W84F3005
                    open={openW84F3005Popup}
                    onClose={(e) => onCloseModal('W84F3005')}
                    FormID={'W09F2272'}
                    TransID={dataPopup.current?.rowData?.VoucherID ?? ''}
                />
            )}

            <ApprovalsPopover
                ref={refApprovalPopover}
                open={openApprovals}
                onClose={onCloseApproval}
                loading={approving}
                anchorEl={dataApproval.current?.anchorEl ?? null}
                type={dataApproval.current?.type ?? null}
                errorNote={errorNote}
                onApproval={(note) => saveApprovals(1, note)}
                onCancel={(note) => saveApprovals(100, note)}
            />
        </>
    );
};

export default W09F2272;
