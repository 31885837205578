/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 13/9/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    flex: {
        display: 'flex',
    },
    alignCenter: {
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    wordWrap: {
        '& div': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 600,
        }
    },
}));
