/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/2/2021
 * @Example
 */

import React, {useCallback, useEffect, useRef, useState} from "react";
import {bindActionCreators, compose}                     from "redux";
import {IconButton, makeStyles}                          from "@material-ui/core";
import {connect}                                         from "react-redux";
import ActionToolbar                                     from "../../../common/toolbar/action-toolbar";
import ButtonGeneral                                     from "../../../common/button/button-general";
import UserImage                                         from "../../../common/user/user-image";
import Icons                                             from "../../../common/icons/";
import Config                                            from "../../../../config";
import * as W09F3000Actions                              from "../../../../redux/W0X/W09F3000/W09F3000_actions";
import {Image, FormGroup, Row, Col}                      from "react-bootstrap";
import {browserHistory}                                  from "react-router";
import _                                                 from "lodash";
import {LoadPanel}                                       from "devextreme-react";
import Tooltip                                           from "@material-ui/core/Tooltip";
import InlineSVG                                         from "react-inlinesvg";
import Popover                                           from "../../../../components/grid-container/popover-action";
import Paging                                            from "../../../grid-container/paging";
import History                                           from "../../../libs/history";
import * as generalActions                               from "../../../../redux/general/general_actions";
import W09F3005Popup                                     from "./W09F3005Popup";
import Modal                                             from "../../../common/modal/modal";

const useStyles = makeStyles(theme => ({
    itemRoot:       {
        background:   '#FFFFFF',
        boxShadow:    '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        borderTop:    '4px solid ' + theme.palette.primary.main
    },
    itemInfo:       {
        padding: '10px 15px 15px 15px'
    },
    itemImage:      {
        height:         120,
        width:          '100%',
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        '& img':        {
            width:     '100%',
            height:    '100%',
            objectFit: 'contain'
        },
        cursor:         'pointer'
    },
    itemTitle:      {
        background:    theme.palette.primary.main,
        border:        '1px solid #FFFFFF',
        boxSizing:     'border-box',
        boxShadow:     '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius:  4,
        textTransform: 'uppercase',
        padding:       '6px 15px',
        color:         '#FFFFFF',
        fontWeight:    'bold',
        position:      'relative',
        left:          -22,
        whiteSpace:    'nowrap',
        maxWidth:      '80%',
        textOverflow:  'ellipsis',
        overflow:      'hidden',
        cursor:        'pointer'
    },
    itemMember:     {
        transition:            'all .3s',
        '&:not(:first-child)': {
            marginLeft: -20
        },
        '&:hover':             {
            marginRight: 17,
            transform:   'scale(1.3)'
        }
    },
    itemMemberMore: {
        width:          32,
        height:         32,
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'center',
        background:     '#7F828E',
        border:         '1px solid #FFFFFF',
        boxSizing:      'border-box',
        borderRadius:   '50%',
        marginLeft:     -20,
        zIndex:         1,
        fontWeight:     500,
        cursor:         'pointer'
    }
}));

const W09F3000 = (props) => {
    const _initFilter        = {
        skip:  0,
        limit: 12
    };
    const _initW09F3005Popup = {
        open: false,
        mode: "",
        data: null
    };

    const {dataGrid}                                = props;
    const [loading, setLoading]                     = useState(false);
    const [mode, setMode]                           = useState(1); //Dang tham gia
    const [showW09F3005Popup, setShowW09F3005Popup] = useState(_initW09F3005Popup); //Dang tham gia

    const filter      = useRef(_initFilter);
    const iconOutline = useRef(<InlineSVG src={require('./icons/people_outline.svg')}/>);
    const iconFill    = useRef(<InlineSVG src={require('./icons/people_fill.svg')}/>);

    const loadDataGrid = () => {
        const params = {
            GroupID:  "",
            Language: Config.language || "84",
            Mode:     mode,
            skip:     filter.current.skip || 0,
            limit:    filter.current.limit || 12,
        };
        setLoading(true);
        props.W09F3000Actions.loadGrid(params, error => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    useEffect(loadDataGrid, [mode]);

    const onChangePage = page => {
        filter.current.skip = page * filter.current.limit;
        loadDataGrid();
    };

    const onChangePerPage = perPage => {
        filter.current.skip  = 0;
        filter.current.limit = perPage;
        loadDataGrid();
    };

    const onChangeMode = (m) => {
        if ((m || m === 0) && m !== mode) {
            setMode(m);
        }
    };

    const onOpenModal  = (mode, data = null) => {
        setShowW09F3005Popup({
            open: true,
            mode: mode || "add",
            data: data
        });
    };
    const onCloseModal = () => {
        setShowW09F3005Popup({
            open: false,
            data: null
        });
        loadDataGrid();
    };

    const onAction = useCallback(async (mode, data) => {
        if (!mode) return false;
        let FormID = "";
        switch (mode) {
            case "add":
                onOpenModal(mode);
                break;
            case "edit":
                onOpenModal(mode, data);
                break;
            case "view":
                FormID = "W09F3010";
                break;
            case "delete":
                const {GroupID} = data || {};
                if (GroupID) {
                    Config.popup.show("YES_NO", Config.lang("DHR_Xoa_nhom_se_xoa_tat_ca_du_lieu_Ban_co_chac_muon_thuc_hien_dieu_nay"), () => {
                        const params = {GroupID};
                        setLoading(true);
                        props.W09F3000Actions.deleteGrid(params, async (errors) => {
                            setLoading(false);
                            if (errors) {
                                Config.popup.show("ERROR", errors);
                                return false;
                            } else {
                                Config.notify.show("success", Config.lang("DHR_Xoa_thanh_cong"), 2000);
                                await saveHistory(data);
                                loadDataGrid();
                            }
                        })
                    });
                }
                break;
            default:
                break;
        }
        if (FormID) {
            browserHistory.push({
                pathname: Config.getRootPath() + FormID,
                state:    {
                    mode:    mode,
                    GroupID: _.get(data, "GroupID", "")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveHistory = async (data) => {
        const {GroupID, GroupName} = data;
        if (GroupID) {
            const _data = {
                GroupName: GroupName,
                User:      `${Config.profile.UseID} - ${Config.profile.UserName}`
            };

            const captions = {
                GroupName: "DHR_Ten_nhom",
                User:      "data_User"
            };

            const options = {
                data:            _data,
                captions:        captions,
                action:          3,
                ModuleID:        "D09",
                TransID:         GroupID,
                TransactionID:   "W09F3000",
                TransactionName: "Nhóm",
            };
            const history = new History(options);
            // const allData = await history.get();
            // history.createDetailHistory("DHR_Nhan_vien", [], [], captions,"key",  null, options ); //Create detail..
            // console.log('test', allData);
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("DHR_Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    return (
        <>
            <Modal open={_.get(showW09F3005Popup, "open", false)}
                   maxWidth={"md"}
                   fullWidth={true}
                   onClose={() => onCloseModal('close')}
                   title={Config.lang("DHR_Thong_tin_nhom")}
            >
                <W09F3005Popup
                    mode={_.get(showW09F3005Popup, "mode", "add")}
                    data={_.get(showW09F3005Popup, "data", null)}
                    onClose={() => onCloseModal()}
                />
            </Modal>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{
                    my: 'center',
                    of: "#root"
                }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <FormGroup>
                <ActionToolbar title={Config.lang("DHR_Nhom")}>
                    <div className={"display_row align-center align-between flex-wrap"} style={{width: "100%"}}>
                        <ButtonGeneral
                            name={Config.lang('DHR_Them')}
                            // disabled={gridLoading || !iPermission >= 2}
                            typeButton={'add'}
                            style={{textTransform: 'uppercase'}}
                            size={"large"}
                            onClick={() => onAction("add")}
                        />
                        <div>
                            <ButtonGeneral
                                name={Config.lang('DHR_Dang_tham_gia')}
                                // disabled={gridLoading || !iPermission >= 2}
                                color={mode === 1 ? "info" : "primary"}
                                variant={mode === 1 ? "contained" : "outlined"}
                                icon={iconOutline.current}
                                style={{textTransform: 'uppercase'}}
                                size={"large"}
                                className={"mgr10"}
                                onClick={() => onChangeMode(1)}
                            />
                            <ButtonGeneral
                                name={Config.lang('DHR_Tat_ca')}
                                // disabled={gridLoading || !iPermission >= 2}
                                color={mode === 0 ? "info" : "primary"}
                                variant={mode === 0 ? "contained" : "outlined"}
                                icon={iconFill.current}
                                style={{textTransform: 'uppercase'}}
                                size={"large"}
                                onClick={() => onChangeMode(0)}
                            />
                        </div>
                    </div>
                </ActionToolbar>
            </FormGroup>
            <FormGroup>
                <Row>
                    {dataGrid && dataGrid.rows && dataGrid.rows.map((item, idx) => {
                        return (
                            <Col key={idx} xs={12} sm={6} md={4} lg={3} className={"mgb15"}>
                                <CellItem data={item} onAction={onAction}/>
                            </Col>
                        );
                    })}
                </Row>
                <Paging
                    typeShort={false}
                    totalItems={dataGrid.total}
                    skipPerPage={_.get(filter, 'current.skip', 0)}
                    currentPage={Math.ceil(filter.current.skip / filter.current.limit)}
                    itemPerPage={_.get(filter, 'current.limit', 0)}
                    listPerPage={[12, 24, 36, 50]}
                    className={"paging-fixed"}
                    onChangePage={(page) => {
                        if (onChangePage) onChangePage(page);
                    }}
                    onChangePerPage={(per) => {
                        if (onChangePerPage) onChangePerPage(per);
                    }}
                />
            </FormGroup>
        </>
    );
};

const CellItem = React.memo((props) => {
    const classes                       = useStyles();
    const {data}                        = props;
    const key                           = "MemberID";
    const Members                       = [..._.get(data, "member", [])];
    const [listMembers, setListMembers] = useState(null);
    const refShowMore                   = useRef(null);
    const refPopupMember                = useRef(null);
    const refPopupAction                = useRef(null);

    const onShowMore = useCallback(() => {
        const MemberIDs = Members.map(m => m[key]);
        const listUsers = Config.getListUser(MemberIDs, "UserID");
        setListMembers(listUsers);
    }, [Members]);

    const onShowAction = useCallback((e, data) => {
        if (refPopupAction && refPopupAction.current) refPopupAction.current.instance.show(e.target);
    }, [refPopupAction]);

    const _onAction = (key, data) => {
        const {onAction} = props;
        if (refPopupAction && refPopupAction.current) refPopupAction.current.instance.hide();
        if (onAction) onAction(key, data);
    };

    useEffect(() => {
        if (refPopupMember && refPopupMember.current) refPopupMember.current.instance.show(refShowMore.current);
    }, [listMembers]);

    const renderImage = useCallback(() => {
        let GroupPicture = _.get(data, "GroupPicture", "");
        GroupPicture     = GroupPicture
            ? (GroupPicture.includes("http") ? GroupPicture : Config.getCDNPath() + GroupPicture)
            : require("../../../../assets/images/general/no-image.svg");
        return <Image src={GroupPicture}/>
    }, [data]);

    const renderMembers = useCallback(() => {
        const limit = 5;
        if (Members.length <= 0) return null;
        const isMore = Members.length > limit ? Members.length : 0;
        return (
            <div className={"display_row align-center valign-middle"}>
                {Members && Members.splice(0, limit).map((member, idx) => {
                    return <UserImage
                        key={idx}
                        className={classes.itemMember}
                        data={member}
                        keyExpr={"MemberID"}
                        valueExpr={"UserID"}
                        width={32} height={32}
                        popoverOption={{
                            showDepartment: false,
                            showProject: false,
                            showDuty: false,
                            showDateJoined: false
                        }}
                    />
                })}
                {isMore > 0 && <div className={classes.itemMemberMore} ref={refShowMore} onClick={onShowMore}>
                    <div style={{
                        fontSize: 10,
                        color:    "#FFFFFF"
                    }}>
                        <i className="fas fa-plus"/>&nbsp;
                        <span style={{fontSize: 12}}>{isMore - limit}</span>
                    </div>
                </div>}
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Members]);

    return (
        <div className={classes.itemRoot}>
            {listMembers && <Popover
                reference={refPopupMember}
                width="auto"
                position="right"
                shading={false}
            >
                <div style={{
                    padding:   10,
                    maxHeight: 400,
                    overflow:  "auto"
                }}>
                    {listMembers.map((data, index) => {
                        return <div key={index} className={"display_row align-center"}>
                            <UserImage data={data} allowHover={false} width={24} height={24}/>
                            <span>{data.UserID} - {data.UserName}</span>
                        </div>
                    })}
                </div>
            </Popover>}
            {Boolean(_.get(data, "IsAdmin", 0)) && <Popover
                reference={refPopupAction}
                width="auto"
                position="left"
                shading={false}
            >
                <div style={{padding: "5px 10px"}}>
                    <Tooltip title={Config.lang("DHR_Sua")}
                    >
                        <IconButton
                            disabled={false}
                            aria-label={"edit"}
                            size={"small"}
                            className={"mgr5"}
                            onClick={() => _onAction("edit", data)}
                        >
                            <Icons name={"edit"}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={Config.lang("DHR_Xoa")}>
                        <IconButton
                            disabled={false}
                            aria-label={"delete"}
                            size={"small"}
                            className={classes.divIconItem}
                            onClick={() => _onAction("delete", data)}
                        >
                            <Icons name={"delete"}/>
                        </IconButton>
                    </Tooltip>
                </div>
            </Popover>}
            <div className={classes.itemImage} onClick={() => _onAction("view", data)}>
                {renderImage(data)}
            </div>
            <div className={classes.itemInfo}>
                <div className={"display_row align-center align-between mgb10"}>
                    <Tooltip title={_.get(data, "GroupName", "")}>
                        <div className={classes.itemTitle} onClick={() => _onAction("view", data)}>
                            {_.get(data, "GroupName", "")}</div>
                    </Tooltip>
                    <IconButton size={"small"} disabled={!Boolean(_.get(data, "IsAdmin", 0))} onClick={onShowAction}>
                        <Icons name={"biggroup"}/>
                    </IconButton>
                </div>
                <div className={"display_row align-center align-between"} style={{minHeight: 32}}>
                    <div
                        className={"text-muted"}>{`${_.get(data, "TotalMember", Members.length)} ${Config.lang("DHR_Thanh_vien")}`}</div>
                    {renderMembers()}
                </div>
            </div>
        </div>
    );
});

export default compose(
    connect(
        state => ({
            dataGrid: state.W09F3000.getGrid,
        }),
        dispatch => ({
            generalActions:  bindActionCreators(generalActions, dispatch),
            W09F3000Actions: bindActionCreators(W09F3000Actions, dispatch)
        })
    ),
)(W09F3000);
