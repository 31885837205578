import React, {useEffect, useState, useRef} from 'react';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";

import * as generalActions from "../../../../redux/general/general_actions";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as W09F2281Actions from "../../../../redux/W0X/W09F2281/W09F2281_actions";
import * as W09F2060Actions from "../../../../redux/W0X/W09F2060/W09F2060_actions";

import _ from "lodash";
import {Col, FormGroup, Row} from "react-bootstrap";
import withStyles from "@material-ui/core/styles/withStyles";
import {Column, Summary, TotalItem} from "devextreme-react/data-grid";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ModalHeader,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Typography,
    ButtonIcon,
    TextInput,
    Tooltip,
} from "diginet-core-ui/components";

import Config from "../../../../config";
import W09F6000 from "../../../W0X/W09/W09F6000/W09F6000";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import Attachments from "../../../common/attachments/attachments";

import {FormHelperText} from "@material-ui/core";
import {MForm} from "../../../common/form-material";
import HeadClick from "../../../grid-container/head-click";
import History from "../../../libs/history";

const styles = () => ({
    bgRowDiff: {
        "& td": {
            backgroundColor: "lightblue"
        }
    },
    hidden: {
        height: 0,
        overflow: "hidden",
        display: "none"
    },
});

const _initDataForm = {
    VoucherID: '',
    VoucherName: '',
    AppStatusID: '',
    Notes: ''
};

const W29F2061 = ({ open, mode = "add", iPermission = 0, data: datForm , onClose, classes,FormID,  ...rest}) => {
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [dataGridEmployee, setDataGridEmployee] = useState([]);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [errors, setErrors] = useState({
        VoucherName: ""
    });

    const [isLoadingForm, setLoadingForm] = useState(false);
    const [isLoadingGrid, setLoadingGrid] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [isLoadingAttachment, setLoadingAttachment] = useState(false);
    const [isShowModalEmployeeChosen, setShowModalEmployeeChosen] = useState(false);
    const [filterGridValue, setFilterGridValue] = useState([]);

    let editting = useRef(0);
    let dataGrid = useRef(null);
    let dataOldMaster = useRef([]);
    let dataOldEmployee = useRef([]);
    let attachments = useRef([]);
    let deletedFile = useRef([]);
    const selectedRange = useRef(null);
    const filterColIndx = useRef(null);
    const filterGrid = useRef({});

    /**
     * Load data when init screen
     */
    useEffect(() => {
        loadRewardCaption();
        loadCboDisRewardForm();
        loadCboProDisRewardLevel();
        if (mode !== "add") {
            loadForm();
            loadGrid();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * load data dropdown DisRewardForm in Filter Header
     */
    const loadCboDisRewardForm = () => {
        rest.w09f2060Actions.loadCboDisRewardForm(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    /**
     * load data dropdown ProDisRewardLevel in Filter Header
     */
    const loadCboProDisRewardLevel = () => {
        rest.w09f2060Actions.loadCboProDisRewardLevel(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    /**
     * load data master
     */
    const loadForm = () => {
        const {VoucherID, AppStatusID} = datForm;
        setLoadingForm(true);
        rest.w09f2281Actions.loadForm({VoucherID}, (error, result)=>{
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else{
                setDataForm({...result, AppStatusID});
                if(mode === "edit") {
                    dataOldMaster.current = JSON.parse(JSON.stringify(result));
                }
            }
            setLoadingForm(false);
        })
    };

    /**
     * load data grid employee and list attachment
     */
    const loadGrid = () => {
        const {VoucherID} = datForm;
        setLoadingGrid(true);
        setLoadingAttachment(true);
        rest.w09f2281Actions.loadEmployee({VoucherID}, (error, data)=>{
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else {
                const dataGrid = data?.loadGrid?.map(i=>({
                    ...i,
                    IsCheck:!!i.IsCheck,
                })) || [];
                dataOldEmployee.current=data?.loadGrid.map(rs=>({...rs}));
                setDataGridEmployee(dataGrid || []);
                setDataOldAttachments(data?.loadAttachment || [])
            }
            setLoadingGrid(false);
            setLoadingAttachment(false);
        })
    };

    /**
     * load dynamic caption for Grid Employee
     */
    const loadRewardCaption = () => {
        rest.w09f2060Actions.loadRewardCaption({Language: Config.language || "84"}, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    /**
     * action when submit popup "Chon_nhan_vien"
     * @param dataEmployeesID {array} - list employee chosen
     */
    const onChosenEmployees = (dataEmployeesID) => {
        setShowModalEmployeeChosen(false);
        setLoadingGrid(true)
        rest.w09f2281Actions.loadChooseEmployee((error, resData)=>{
            setLoadingGrid(false);
            if (resData) {
                let dataNonOverlapping = _.uniqBy([...dataGridEmployee, ...resData], "EmployeeID");
                dataNonOverlapping = dataNonOverlapping.map(i=>({
                    ...i,
                    IsCheck:!!i.IsCheck,
                }));
                setDataGridEmployee(dataNonOverlapping);
            }
        });
        if (_.size(dataEmployeesID) > 1) setErrors({...errors, dataGrid: ""});
    };

    /**
     * action when change file attachment
     * @param e
     */
    const onChangeAttachments = (e) => {
        attachments.current = _.get(e, "uploadedFiles", []);
        if (e.deletedFiles && _.size(e.deletedFiles) > 0) {
            deletedFile.current = [...e.deletedFiles];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            setDataOldAttachments(_.filter(dataOldAttachments, att => _.indexOf(_arrRemove, att.AttachmentID) < 0));
        }
    };

    /**
     * action when upload attachment
     * @param value
     */
    const onUploading = value => {
        setUploading(value)
    };

    /**
     * show popup "YES/NO" when remove row in grid
     * @param e - row data
     * @returns {null}
     */
    const onDeleteRow = (e) => {
        if(!e) return null;
        Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const { data, rowIndex } = e.row;
            if(mode === "add") {
                const dataFilter = dataGridEmployee.filter(item => item.EmployeeID !== data.EmployeeID);
                setDataGridEmployee(dataFilter);
            }
            if(mode === "edit") {
                const dataT = [...dataGridEmployee];
                dataT[rowIndex].Deleted = 1;
                setDataGridEmployee(dataT);
                e.component.cellValue(e.rowIndex, "Deleted", 1);
                e.component.saveEditData();
            }
        });
    };

    /**
     * parse list attachments
     * @returns {[]}
     */
    const getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: _.get(att, "url", ""),
                FileName: _.get(att, "fileName", ""),
                FileSize: _.get(att, "fileSize", ""),
                FileExt: _.get(att, "fileExt", ""),
            });
        });
        if (dataOldAttachments && _.size(dataOldAttachments) > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    /**
     * action on click button Save
     */
    const saveGrid = () => {
        dataGrid.instance.saveEditData().done(() => {
            setTimeout(() => {
                if (editting.current === 0) {
                    onStore();
                }
                else{
                    editting.current = 0;
                }
            }, 200);
        });
    };

    /**
     * execution store save
     * @returns {Promise<boolean>}
     */
    const onStore = async () => {
        let actionSaveMode = mode === "add" ? "add" : "edit";
        let dataSource = dataGrid.instance.option("dataSource");
        dataSource = dataSource.map(i=>({...i, ValidDate: i.ValidDate ? new Date(i.ValidDate).toLocaleDateString() : null}));
        const dataT = JSON.parse(JSON.stringify(dataSource));
        const dataNew = dataT.map((item) => {
            item.IsCheck=+!!item.IsCheck;
            return item;
        });
        const validateMasterRules = [
            {
                name: "VoucherName",
                rules: ["isRequired"],
                value: dataForm.VoucherName || ""
            }
        ];
        const validateForm = MForm.formValidation(validateMasterRules);
        if (Object.keys(validateForm).length > 0) {
            setErrors(validateForm);
            if(setErrors(validateForm)) return false;
        }

        if(!dataNew.length){
            let message = Config.lang("Ban_chua_chon_nhan_vien");
            Config.popup.show('ERROR', message);
            return;
        }

        if(dataNew.find(i=>!i.ValidDate)){
            let message = Config.lang("Ton_tai_nhan_vien_chua_co_ngay_hieu_luc");
            Config.popup.show('ERROR', message);
            return;
        }

        const arrAttachments = getAttachments();
        const params = {
            ...dataForm,
            Language: Config.language || '|84',
            data: dataNew ? JSON.stringify(formatDateDataSource(dataNew)) : "",
            arrAttachment: JSON.stringify(arrAttachments),
        };

        if(mode === "add") delete params.VoucherID;

        rest.w09f2281Actions[actionSaveMode](params, async (errors, result) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            else if (result) {
                if (result.Status === 0) {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    await saveHistory(params, actionSaveMode, datForm?.VoucherID);
                    onClose && onClose();
                } else {
                    const msg = result.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', msg);
                }
            }
        });
    };

    /**
     * save History changed after update or add new
     * @param data - data voucher
     * @param mode - mode action
     * @param _VoucherID
     * @returns {Promise<boolean>}
     */
    const saveHistory = async (data, mode, _VoucherID) => {
        if(!data) return false;
        const TransID = mode === "add" ? _VoucherID :  _.get(data, "VoucherID", "");
        const _mode = mode === "add" ? 0 : 1;
        let dataSource = dataGrid.instance.option('dataSource');
        if (TransID) {

            //name Content
            const captions = {
                VoucherName : "ten_phieu",
                Notes: "ghi_chu",
            };

            // name detail grid
            let detailCaptions = {
                EmployeeID: "nhan_vien",
                ValidDate : "ngay_hieu_luc",
                ProDisRewardFormName: "hinh_thuc_khen_thuong",
                ProDisRewardLevelID: "cap_khen_thuong",
                ProContent: "noi_dung",
                ProNotice : "ghi_chu",
            };
            if(rest.getRewardCaption){
                rest.getRewardCaption.forEach(i=>{
                    detailCaptions[i.Code] = i.Short;
                });
            }

            // data form master
            const _dataOld = dataOldMaster.current;

            const options = {
                data: data,
                captions: captions,
                dataCompare: _dataOld,
                action: _mode,
                ModuleID: "D09",
                TransID: TransID,
                TransactionID: "W09F2280",
                TransactionName: Config.lang("Khen_thuong_hang_loat"),
                itemRender: (e, type) => {
                    const {key, data, oldData} = e;
                    let dataRender = data;
                    if(type.includes("_OLD")) dataRender = oldData;
                    if (key === "EmployeeID") {
                        return dataRender.EmployeeID ? `${dataRender.EmployeeID} - ${dataRender.EmployeeName}` : "";
                    }
                    else if (key === "ValidDate") {
                        return Config.convertDate(dataRender.ValidDate,'')
                    }
                }
            };

            const dataOld = dataOldEmployee.current.map(i=>{
                i.IsCheck = +!!i.IsCheck;
                return i;
            });

            const dataNew = dataSource.map(i=>{
                i.IsCheck = +!!i.IsCheck;
                return i;
            });

            const history = new History(options); //Init history
            history.createDetailHistory("Khen_thuong_hang_loat", dataNew, dataOld, detailCaptions, "EmployeeID", null, options); //Create detail..
            await history.save();
        }
    };

    /**
     * format data source
     * @param data
     * @returns {*}
     */
    const formatDateDataSource = (data) => {
        let dataFormat = data.map(item => {
            item.ValidDate = Config.convertDate(item.ValidDate, null,'YYYY-MM-DD');
            [...Array(10).keys()].forEach(i=>{
                const name = "ProCoefficient" + (i<9 ? `0${i+1}` : i+1);
                if(_.isBoolean(item[name])) item[name] = Number(item[name]);
            });
            return item;
        });
        return dataFormat;
    };

    /**
     * render column action
     * @param e - row data
     * @returns {*}
     */
    const renderAction = (e) => {
        return(
            <GridActionBar>
                <ButtonIcon name={"Delete"}
                            viewType={"text"}
                            circular
                            viewBox
                            onClick={() => onDeleteRow(e)}/>
            </GridActionBar>
        )
    };

    /**
     * get filter grid
     * @param isClear
     * @returns {[]}
     */
    const getFilterGrid = (isClear) => {
        if (isClear) filterGrid.current = {};
        const filter = filterGrid.current;
        const dataFilter = [];
        Object.keys(filter).forEach(fil => {
            const itemFilter = filter[fil];
            if (itemFilter && itemFilter.length > 0) {
                dataFilter.push(filter[fil]);
                dataFilter.push("and");
            }
        });
        dataFilter.pop();
        return dataFilter;
    };

    /**
     * render header column to headclick
     * @param e
     * @returns {boolean|*}
     */
    const renderHeaderColumns = (e) => {
        if (!e) return false;
        return(
            <HeadClick selectedRange={selectedRange.current}
                       dataHeaderCell={e}
                       allowClick={true}
                       conditionCopy={(eChild) => {
                           const {data} = eChild;
                           const dataSource = dataGrid.instance.option("dataSource");
                           return (dataSource.find(i=>!!i.IsCheck) && eChild.key !== "IsCheck" ) ? data.IsCheck : 1;
                       }}
            />
        )
    };

    /**
     * calculator summary grid
     * @param options
     */
    const calculateSelectedRow = (options) => {
        if (options.name === 'RowsSummary') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if (!options.value?.Deleted) {
                    options.totalValue += 1;
                }
            }
        }
    };

    /**
     * action when change value form master
     * @param key
     * @param e
     * @returns {boolean}
     */
    const handleChange = (key,e) => {
        if(!key) return false;
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        setDataForm({...dataForm, [key]: value});
    };

    return <>
        {isShowModalEmployeeChosen &&
            <W09F6000
                // loadParams={{ShiftID: shiftID, AttendanceDate: attendanceDate}}
                open={isShowModalEmployeeChosen} dataDefaults={[]}
                FormID={"W09F2280"}
                onChosen={onChosenEmployees}
                onClose={() => setShowModalEmployeeChosen(false)}
            />
        }
        <Modal open={open}
               onClose={() => onClose(_.get(datForm, "reLoad", true))}
        >
            <ModalHeader>{Config.lang("Lap_khen_thuong_hang_loat")}</ModalHeader>
            <ModalBody style={{height: "100vh"}}>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <Tooltip title={dataForm.VoucherName}
                                 tooltipMaxWidth={300}
                                 direction={"top"}
                                 style={{width:'100%'}}
                        >
                            <TextInput
                                onChange={(e) => handleChange('VoucherName', e)}
                                value={dataForm.VoucherName}
                                label={Config.lang('Ten_phieu')}
                                required
                                disabled={mode === 'view'}
                                error={errors && errors['VoucherName']}
                            />
                        </Tooltip>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <Tooltip title={dataForm.Notes}
                                 tooltipMaxWidth={300}
                                 style={{width:'100%'}}
                        >
                            <TextInput
                                onChange={(e) => handleChange('Notes', e)}
                                value={dataForm.Notes}
                                label={Config.lang('Ghi_chu')}
                                disabled={mode === 'view'}
                                error={errors && errors['VoucherName']}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <FormGroup className={"mgb5 display_col align-between"}>
                    <div className={`display_row ${FormID === 'W09F2282' ? "valign-top" : "align-between"} align-center`} style={{marginBottom:8}}>
                        <Typography type={"h3"}>{Config.lang("Danh_sach_khen_thuong")}</Typography>
                        {FormID !== 'W09F2282' &&
                            <Button text={Config.lang('Chon_nhan_vien')}
                                    startIcon={"AddPerson"}
                                    viewType={"outlined"}
                                    size={"large"}
                                    color={"primary"}
                                    disabled={!!Number(dataForm.AppStatusID)}
                                    onClick={() => setShowModalEmployeeChosen(true)}
                            />
                        }
                    </div>
                    <GridContainer
                        reference={ref => dataGrid = ref}
                        style={{border: "none", overflowX: "scroll"}}
                        dataSource={dataGridEmployee}
                        keyExpr={"EmployeeID"}
                        showRowLines={false}
                        showBorders={false}
                        showColumnLines={false}
                        rowAlternationEnabled={false}
                        noDataText={Config.lang("No_data")}
                        loading={isLoadingGrid}
                        hoverStateEnabled={true}
                        height={Config.getHeightGrid() - 280}
                        pagerFullScreen={false}
                        typePaging={"remote"}
                        editing={{
                            mode: 'cell',
                            refreshMode: 'reshape',
                            allowUpdating: mode !== 'view',
                            texts: {
                                confirmDeleteMessage: ""
                            },
                            startEditAction: "click"
                        }}
                        filterRow={{
                            visible: true,
                            showOperationChooser: false,
                        }}
                        filterPanel={{
                            visible: true
                        }}
                        sorting={{
                            mode: "none"
                        }}
                        filterBuilderPopup={{
                            onInitialized: (e) => {
                                e.component.dispose();
                                e.element.remove();
                            }
                        }}
                        onRowValidating={e=>{
                            editting.current = +!e.isValid;
                        }}
                        filterValue={filterGridValue}
                        onFilterGrid={(e) => {
                            let {column, filterValue, columnIndex} = e;
                            filterColIndx.current= columnIndex;
                            e.component.saveEditData();
                            if (column) {
                                if (column.dataField === "EmployeeID") {
                                    let dataFilter = [];
                                    if (filterValue) {
                                        if (filterValue.indexOf(",") > -1) {
                                            const arrFilters = filterValue.split(",");
                                            arrFilters.forEach(f => {
                                                dataFilter.push(["EmployeeID", "=", f.trim()]);
                                                dataFilter.push("or");
                                            });
                                            dataFilter.pop();
                                        } else {
                                            dataFilter = ["EmployeeID", "contains", filterValue];
                                        }
                                    }
                                    filterGrid.current.EmployeeID = dataFilter;
                                } else {
                                    let operator = "contains";
                                    if (["IsCheck", "ValidDate","ProDisRewardFormID","ProDisRewardLevelID"].includes(column.dataField)) operator = "=";
                                    filterGrid.current[column.dataField] = (filterValue || typeof filterValue === "boolean") ? [column.dataField, operator, filterValue] : [];
                                }
                                setFilterGridValue(getFilterGrid());
                            } else {
                                filterGrid.current = {};
                                setFilterGridValue([]);
                            }
                        }}
                        onCellPrepared={e=>e.separator=","}
                        onRowUpdated={(e) => {
                            e.data.IsUpdated = 1;
                        }}
                        onEditorPreparing={(e) => {
                            if((e.parentType === "searchPanel" || e.parentType === "filterRow") && e.dataField === "EmployeeID") {
                                e.updateValueTimeout = 2500;
                            }
                        }}
                        onRowPrepared={(e) => {
                            if (!e) return false;
                            const {data}=e;
                            if (data?.Updated === 1 || data?.AddEmp === 0) {
                                e.rowElement.classList.add(classes.bgRowDiff);
                            }
                            if (data?.Deleted === 1){
                                e.rowElement.classList.add(classes.hidden);
                            }
                        }}
                        onCellSelectionChanged={(e) => {
                            if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                selectedRange.current = e.selectedRange;
                            }
                        }}
                    >
                        <Column
                            width={100}
                            alignment={"right"}
                            allowEditing={false}
                            cellRender={renderAction}
                            allowSorting={false}
                            visible={Config.isMobile && mode!=="view" && Number(dataForm.AppStatusID)}
                        />
                        <Column dataField={"VoucherID"}
                                visible={false}
                        />
                        <Column dataField={"ProTransID"}
                                visible={false}
                        />
                        <Column
                            alignment={"center"}
                            dataField={"IsCheck"}
                            caption={Config.lang("Chon")}
                            trueText={Config.lang("Co")}
                            falseText={Config.lang("Khong")}
                            dataType={"boolean"}
                            headerCellRender={renderHeaderColumns}
                            minWidth={80}
                            fixed={true}
                            allowEditing={true}
                            allowCopying={true}
                            allowPasting={true}
                            allowFiltering={true}
                        />
                        <Column caption={Config.lang("Ma_nhan_vien")}
                                dataField={"EmployeeID"}
                                width={130}
                                fixed={true}
                        />
                        <Column caption={Config.lang("Ten_nhan_vien")}
                                dataField={"EmployeeName"}
                                width={180}
                                fixed={true}
                        />
                        <Column caption={Config.lang("Ngay_hieu_luc")}
                                dataType="date"
                                format={'dd/MM/yyyy'}
                                width={180}
                                dataField={"ValidDate"}
                                headerCellRender={renderHeaderColumns}
                                allowEditing={true}
                                allowCopying={true}
                                allowPasting={true}
                        />
                        <Column
                            width={200}
                            caption={Config.lang("Hinh_thuc_khen_thuong")}
                            dataField={"ProDisRewardFormID"}
                            headerCellRender={renderHeaderColumns}
                            allowEditing={true}
                            allowCopying={true}
                            allowPasting={true}
                            lookup={{
                                allowClearing: true,
                                dataSource: rest.getCboDisRewardForm,
                                valueExpr: "ProDisRewardFormID",
                                displayExpr: "ProDisRewardFormName"
                            }}
                        />
                        <Column
                            width={200}
                            caption={Config.lang("Cap_khen_thuong")}
                            dataField={"ProDisRewardLevelID"}
                            headerCellRender={renderHeaderColumns}
                            allowEditing={true}
                            allowCopying={true}
                            allowPasting={true}
                            lookup={{
                                allowClearing: true,
                                dataSource: rest.getCboProDisRewardLevel,
                                valueExpr: "ProDisRewardLevelID",
                                displayExpr: "ProDisRewardLevelName"
                            }}
                        />
                        <Column caption={Config.lang("Noi_dung")}
                                dataField={"ProContent"}
                                headerCellRender={renderHeaderColumns}
                                minWidth={200}
                                allowEditing={true}
                                allowCopying={true}
                                allowPasting={true}
                        />
                        <Column caption={Config.lang("Ghi_chu")}
                                dataField={"ProNotice"}
                                headerCellRender={renderHeaderColumns}
                                minWidth={200}
                                allowEditing={true}
                                allowCopying={true}
                                allowPasting={true}
                        />
                        {rest.getRewardCaption &&
                            rest.getRewardCaption.map((item, i) => (
                                <Column key={i}
                                        dataType={"number"}
                                        minWidth={80}
                                        format={{
                                            type: 'decimal',
                                            min: 0
                                        }}
                                        calculateDisplayValue={cellValue=>Number(cellValue[item.Code] || 0).toLocaleString()}
                                        editorOptions={{
                                            min: 0,
                                            showClearButton: true,
                                            format: {
                                                precision: Number(item?.Decimals) || 4,
                                            },
                                        }}
                                        visible={item.Disabled !== 1}
                                        caption={item.Short}
                                        alignment={'center'}
                                        dataField={item.Code}
                                        headerCellRender={renderHeaderColumns}
                                        allowEditing={true}
                                        allowCopying={true}
                                        allowPasting={true}
                                />
                            ))
                        }
                        <Column dataField={"Deleted"} visible={false}/>
                        <Column dataField={"Updated"} visible={false}/>
                        <Column dataField={"AddEmp"} visible={false}/>
                        <Column
                            alignment={"center"}
                            fixed={true}
                            fixedPosition={"right"}
                            cellRender={renderAction}
                            width={50}
                            allowEditing={false}
                            allowSorting={false}
                            visible={!Config.isMobile && mode!=="view" && Number(dataForm.AppStatusID)}
                        />
                        <Summary calculateCustomSummary={calculateSelectedRow}>
                            <TotalItem
                                column="IsCheck"
                                displayFormat={`${Config.lang("DHR_Tong")}:`}
                            />

                            <TotalItem
                                name={"RowsSummary"}
                                summaryType="custom"
                                displayFormat={"{0}"}
                                showInColumn="EmployeeID"
                            />
                        </Summary>
                    </GridContainer>
                    <FormHelperText
                        style={{position: "unset", paddingLeft: 16}}
                        error={true}>{errors.dataGrid && errors.dataGrid}
                    </FormHelperText>
                </FormGroup>
                <FormGroup style={{marginBottom: 34, marginTop: 24}}>
                    <Accordion>
                        <AccordionSummary>
                            {Config.lang("Dinh_kem")}
                        </AccordionSummary>
                        <AccordionDetails style={{marginTop:8}}>
                            <Attachments
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={isLoadingForm || isUploading || isLoadingAttachment || Number(dataForm.AppStatusID) || mode === "view"}
                                maxLength={5}
                                uploading={isUploading}
                                onUploading={onUploading}
                                onChanged={onChangeAttachments}
                            />
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
            </ModalBody>
            {FormID !== 'W09F2282' && <ModalFooter>
                <Button label={Config.lang('Luu')}
                        startIcon={"SaveV2"}
                        color={"primary"}
                        viewType={"filled"}
                        style={{textTransform: 'uppercase'}}
                        size={"large"}
                        disabled={
                            isLoadingForm ||
                            isLoadingGrid ||
                            isUploading ||
                            isLoadingAttachment ||
                            Number(dataForm.AppStatusID) ||
                            mode === "view"
                        }
                        onClick={saveGrid}
                />
            </ModalFooter>}
        </Modal>
    </>
};

export default compose(connect(
    (state) => ({
        getCboStatus: state.approvals.getCboStatus,
        getRewardCaption: state.W09F2060.getRewardCaption,
        getCboDisRewardForm: state.W09F2060.getCboDisRewardForm,
        getCboProDisRewardLevel: state.W09F2060.getCboProDisRewardLevel,
    }),
    dispatch => (
        {
            generalActions: bindActionCreators(generalActions, dispatch),
            approvalActions: bindActionCreators(ApprovalActions, dispatch),
            w09f2281Actions: bindActionCreators(W09F2281Actions, dispatch),
            w09f2060Actions: bindActionCreators(W09F2060Actions, dispatch),
        }
    )), withStyles(styles, {withTheme: true}))(W29F2061);
