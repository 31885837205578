/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/29/2020
 * @Example
 */

import React                                             from "react";
import {FormGroup, Col, Row, Image}                      from "react-bootstrap";
import Config                                            from "../../../../config";
import ActionToolbar                                     from "../../../common/toolbar/action-toolbar";
import Approvals                                         from "../../../approvals/approvals";
import LabelText                                         from "../../../common/label-text/label-text";
import {bindActionCreators, compose}                     from "redux";
import {connect}                                         from "react-redux";
import {Input, withStyles, Checkbox, FormControlLabel, FormLabel as Label} from "@material-ui/core";
import InlineSVG                                         from "react-inlinesvg";
import * as ApprovalActions                              from "../../../../redux/approvals/approvals_actions";
import * as generalActions   from "../../../../redux/general/general_actions";
import * as W09F2152Actions  from "../../../../redux/W0X/W09F2152/W09F2152_actions";
import * as W09F2150Actions  from "../../../../redux/W0X/W09F2150/W09F2150_actions";
import ExpansionPanel        from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {Combo, TextField}    from "../../../common/form-material";
import NumberFormat          from "react-number-format";
import DateBoxPicker         from "../../../common/form-material/date-box";
import Filter                from "../../../filter/filter";
import ButtonGeneral         from "../../../common/button/button-general";
import moment                from "moment";
const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        maxWidth: '100%',
        objectFit: 'contain'
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color:"#757575"
    },
    divText : {
        // fontSize: "1rem",
        fontWeight: 500,
        // color:"#575757"
    },
    expandPanel: {
        boxShadow: 'none'
    },
    expandSummary: {
        minHeight: '37px !important',
        padding: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0
        }
    },
    expandDetail: {
        display: 'block',
        padding: '0 0 10px 0'
    }
};

class W09F2152 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm: {},
            isExpanded: false,
            VoucherID: "",
            loadingDutyID: false,
            dataCboCode: [],
            cboEmployeeLoading: false,
            IsSalaryAdjustment: 0,
            Search: "",
            DutyID: "",
            AppStatusID: null,
            Employee: null,
            DateFrom: null,
            DateTo: null,
            dataCboEmployees: {
                rows: [],
                total: 0
            }
        };
        this.filter = {
            skip: 0,
            limit: 10
        };
        this.timer = null;
        this.interval = null;
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2152", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCaption = () => {
        this.props.w09F2150Actions.loadCaption({Language: Config.language || "84" },(error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W09F2152",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({cboEmployeeLoading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({cboEmployeeLoading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadPosition = () => {
        this.setState({loadingDutyID: true});
        this.props.generalActions.getCboDuty2(error => {
            this.setState({loadingDutyID: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadCboCode = () => {
        this.props.w09F2152Actions.getCboCode((error,data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if(data) {
                this.setState({dataCboCode : data});
            }
        });
    };

    loadCboStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || '84'
        };
        this.props.approvalActions.getCboStatus(params, (error) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    handleFilterChange = (key, e) => {
        const {onTextFilterChanged} = this.props;
        switch (key) {
            case "Search":
                this.setState({Search : e.target.value});
                if (onTextFilterChanged) {
                    if (this.timer) clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        onTextFilterChanged(this.filter);
                    }, 500);
                }
                break;
            case "AppStatusID":
                this.setState({AppStatusID : e.value});
                // this.filter.AppStatusID = e.value;
                break;
            case "EmployeeID":
                this.setState({Employee : e.value});
                // this.filter.Employee = e.value;
                break;
            case "DutyID":
                this.setState({DutyID : e.value});
                // this.filter.DutyID = e.value;
                break;
            case "DateFrom":
                this.setState({DateFrom : e.value});
                // this.filter.DateFrom = e.value;
                break;
            case "DateTo":
                this.setState({DateTo : e.value});
                // this.filter.DateTo = e.value;
                break;
            default:
                break;
        }
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return false;
        this.loadCboCode();
        this.loadCaption();
        this.loadPosition();
        this.loadCboStatus();
        this.loadCboEmployees();
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W09F2152",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({
            dataForm: []
        });
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    renderFormInfo = (data) => {
        const {classes, getCaption} = this.props;
        const {isExpanded, dataCboCode} = this.state;
        if (data && data.UserPictureURL && data.UserPictureURL.indexOf('http') < 0) {
            data.UserPictureURL = Config.getCDNPath() + data.UserPictureURL;
        }
        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"}
                                             style={{width: "100%", height: 37}}>
                                            <div className={classes.divAvatar}>
                                                {data && data.UserPictureURL ? (
                                                    <Image className={classes.imgAvatar} src={data.UserPictureURL}/>
                                                ) : (
                                                    <InlineSVG className={classes.imgAvatar}
                                                               src={require('../../../../assets/images/general/user_default.svg')}/>
                                                )}
                                            </div>
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={Config.helpers.getObjectValue(data, "EmployeeName", "")}
                                                disableUnderline={true}
                                            />
                                        </div>
                                    );
                                }}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ma_nhan_vien")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "EmployeeID", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Chuc_vu_moi")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProDutyName", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ngay_hieu_luc")}
                                fullWidth={true}
                                value={Config.convertDate(data.ValidDate, "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ly_do")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProContent", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ghi_chu")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ProNotice","")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                   <div >
                       <FormControlLabel
                           control={
                               <Checkbox
                                   style={{margin:0,padding:0}}
                                   checked={Boolean(data.IsSalaryAdjustment)}
                                   color="primary"
                               />
                           }
                           label={Config.lang("DHR_Dieu_chinh_luong")}
                       />
                   </div>
                </FormGroup>
                <FormGroup>
                    <Row>
                        {data.IsSalaryAdjustment === 1 && dataCboCode && dataCboCode.map((dataCbo, index) => {
                            if (dataCbo.Disabled) return null;
                            let no          = dataCbo && dataCbo.Code ? dataCbo.Code.slice(-2) : "";
                            const dataField = "ProBaseSalary" + no;
                            return (<Col key={index} xs={12} sm={12} md={6} lg={6}>
                                <NumberFormat
                                    className={classes.numberInput}
                                    customInput={TextField}
                                    label={dataCbo.CaptionName + ' ' + Config.lang('DHR_Moi').toLowerCase()}
                                    thousandSeparator={true}
                                    decimalScale={dataCbo.Decimals ? dataCbo.Decimals : 0}
                                    value={data[dataField] ? String(data[dataField]) : "0"}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disabled={true}
                                    variant={"standard"}
                                    margin={"normal"}
                                />
                            </Col>);
                        })}
                        {data.IsSalaryAdjustment === 1 && getCaption && getCaption.map((item, index) => {
                            if (item.Disabled) return null;
                            let no          = item && item.Code ? item.Code.slice(-2) : "";
                            const dataField = "NewSalCoefficient" + no;
                            return (<Col key={index} xs={12} sm={12} md={6} lg={6}>
                                <NumberFormat
                                    className={classes.numberInput}
                                    customInput={TextField}
                                    label={item.CaptionName + ' ' + Config.lang('DHR_Moi').toLowerCase()}
                                    thousandSeparator={true}
                                    decimalScale={item.Decimals ? item.Decimals : 0}
                                    value={data[dataField] ? String(data[dataField]) : "0"}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disabled={true}
                                    variant={"standard"}
                                    margin={"normal"}
                                />
                            </Col>);
                        })}
                    </Row>
                </FormGroup>
                <FormGroup className={"mgt10"}>
                    <ExpansionPanel className={classes.expandPanel + " mgb10"}
                                    onChange={(e, isExpaned) => {
                                        this.setState({isExpanded: isExpaned})
                                    }}>
                        <ExpansionPanelSummary
                            href={""}
                            className={classes.expandSummary}
                            // expandIcon={<img alt={""} src={require('../../../../assets/images/icon-expand.svg')} />}
                            aria-label="Expand"
                        >
                            <h5 style={{margin: '5px 0'}}><b>{Config.lang("DHR_Thong_tin_cu")}</b></h5>&nbsp;
                            {isExpanded ?
                                <Image alt={""}
                                       src={require('../../../../assets/images/icon-collapse.svg')}/>
                                :
                                <Image alt={""}
                                       src={require('../../../../assets/images/icon-expand.svg')}/>
                            }
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.expandDetail}>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <span style={{fontWeight: 600}}>{Config.lang("DHR_Chuc_vu")}:</span>&nbsp;
                                        {Config.helpers.getObjectValue(data, "TempDutyName", "")}
                                    </Col>
                                </Row>
                                <Row>
                                    {Number(data.IsSalaryAdjustment) === 1 && dataCboCode && dataCboCode.map((field, indx) => {
                                        if (field.Disabled) return null;
                                        const no = field && field.Code ? field.Code.slice(-2) : "";
                                        const dataField = "TempBaseSalary" + no;
                                        return (
                                            <Col key={indx} xs={12} sm={6} md={6} lg={6}>
                                                <span style={{fontWeight: 600}}>{field.CaptionName || ""}:</span>&nbsp;
                                                {(data[dataField] && new Intl.NumberFormat().format(data[dataField])) || 0}
                                            </Col>
                                        );
                                    })}
                                    {Number(data.IsSalaryAdjustment) === 1 && getCaption && getCaption.map((field, indx) => {
                                        if (field.Disabled) return null;
                                        const no = field && field.Code ? field.Code.slice(-2) : "";
                                        const dataField = "SalCoefficient" + no;
                                        return (
                                            <Col key={indx} xs={12} sm={6} md={6} lg={6}>
                                                <span style={{fontWeight: 600}}>{field.CaptionName || ""}:</span>&nbsp;
                                               {Number(data[dataField] || 0).toLocaleString()}
                                            </Col>
                                        );
                                    })}
                                </Row>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </FormGroup>
            </>
        )
    };

    loadGrid = () => {
        if(this.refs['Approvals']) this.refs['Approvals'].onReset(true);
    };

    filterRender = () => {
        const {getCboStatus, getCboDuty} = this.props;
        const {dataCboEmployees, cboEmployeeLoading, loadingDutyID} = this.state;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={getCboStatus}
                                            showClearButton={true}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"ApprovalStatus"}
                                            value={this.state.AppStatusID}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            onValueChanged={e => this.handleFilterChange("AppStatusID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={this.state.Employee}
                                            loading={cboEmployeeLoading}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nhan_vien')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDuty)}
                                            displayExpr={item =>
                                                item && `${item.DutyID} - ${item.DutyName}`
                                            }
                                            valueExpr={'DutyID'}
                                            margin={"dense"}
                                            value={this.state.DutyID}
                                            stylingMode={'outlined'}
                                            disabled={loadingDutyID}
                                            showClearButton={true}
                                            placeholder={Config.lang('DHR_Chuc_vu')}
                                            onValueChanged={(e) => this.handleFilterChange("DutyID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                max={this.state.DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                placeholder={"DD/MM/YYYY"}
                                                value={this.state.DateFrom}
                                                onValueChanged={e => this.handleFilterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                min={this.state.DateFrom}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                width={"100%"}
                                                value={this.state.DateTo}
                                                onValueChanged={e =>
                                                    this.handleFilterChange("DateTo", e)
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral name={Config.lang('DHR_Tim_kiem')}
                                               typeButton={'search'}
                                               color={"primary"}
                                               className={"pdl10 pdr10"}
                                               variant={"outlined"}
                                               style={{textTransform: 'uppercase'}}
                                               size={"large"}
                                               onClick={this.loadGrid}/>
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    render() {
        const {iPermission, dataForm, VoucherID, Search, DutyID, AppStatusID, Employee, DateFrom, DateTo} = this.state;
        if (!iPermission) return null;
        const objSearch = {Search, DutyID,ApprovalStatus: AppStatusID, EmployeeID: Employee && Employee.EmployeeID ,DateFrom:  DateFrom ? moment(DateFrom).format("YYYY-MM-DD") : null, DateTo : DateTo ? moment(DateTo).format("YYYY-MM-DD") : null};
        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_de_xuat_thay_doi_chuc_danh")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                parentProps={this.props}
                                FormID={"W09F2152"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                disabledApproval={iPermission < 2}
                                singleClick
                                dataFilter={objSearch}
                                filterRender={this.filterRender}
                                formInfoRender={this.renderFormInfo}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                                disableEscapeKeyDown={false}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

}

export default compose(connect(state => ({
        getCboDuty: state.general.getCboDuty2,
        getCboStatus: state.approvals.getCboStatus,
        getCaption: state.W09F2150.getCaption,
    }),
    (dispatch) => ({
        w09F2152Actions: bindActionCreators(W09F2152Actions, dispatch),
        w09F2150Actions: bindActionCreators(W09F2150Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W09F2152);
