/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 13/09/2021
 * @Example
 */

import { Column } from "devextreme-react/data-grid";
import { Button, ButtonIcon, DatePicker, Dropdown, Label, Tooltip } from 'diginet-core-ui/components';
import { makeStyles } from "diginet-core-ui/theme";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, FormGroup, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2280Actions from "../../../../redux/W0X/W09F2280/W09F2280_actions";
import Status from "../../../common/status/status";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Filter from "../../../filter/filter";
import GridContainer from "../../../grid-container/grid-container";
import History from "../../../libs/history";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import W09F2281 from "../W09F2281/W09F2281";

const useStyles = makeStyles(() => ({
    dateInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    divText: {
        fontWeight: 500,
    },
    VoucherNo: {
        maxWidth: 500,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'left'
    }
}));

const W09F2280 = (props) => {
    const FormID = "W09F2280";
    const Language = Config.language || "84";
    const classes = useStyles();

    const { getCboAppStatus, dataGrid, location } = props;
    const [iPermission, setPermission] = useState(null);
    const [gridLoading, setGridLoading] = useState(false);
    const [cboAppStatusLoading, setCboAppStatusLoading] = useState(false);
    const [showW84F3005Popup, setShowW84F3005Popup] = useState(false);
    const [cboEmployeesLoading, setCboEmployeesLoading] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0
    });
    const [dataFilter, setDataFilter] = useState({
        EmployeeID: "", // Nhân viên
        AppStatusID: "", // Trạng thái duyệt
        DateFrom: null,
        DateTo: null,
    });
    const [showW09F2281, setShowW09F2281] = useState(null);

    const currentRowClick = useRef({});
    const filterCboEmployees = useRef({
        skip: 0,
        limit: 20
    });
    const dataFilterGrid = useRef({
        skip: 0,
        limit: 10,
    });

    const loadPermission = async () => {
        await props.generalActions.getPermission("W09F2280", (isPer) => {
            if (isPer) setPermission(isPer);
        });
    };

    useEffect(() => {
        if (_.isNull(iPermission)) loadPermission();
        if (iPermission > 0) {
            loadDataGrid();
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    useEffect(() => {
        if (!_.isEmpty(_.get(dataGrid, "rows", []))) {
            Config.callChildForm({
                FormID: "W09F2280",
                keyExpr: "VoucherID",
                data: _.get(dataGrid, "rows", []),
                // onLoad: (params) => { if (params.ID) loadDataGrid(params.ID) },
                onAction: (e, data) => {
                    if (data && data.VoucherID) {
                        setShowW09F2281({
                            mode: 'view',
                            data
                        });
                    }
                }
            }, { location });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    const onSearch = () => {
        loadDataGrid();
    };

    const loadDataGrid = (VoucherID = "") => {
        const { EmployeeID, AppStatusID, DateFrom, DateTo } = dataFilter;
        const { skip, limit } = dataFilterGrid.current;
        const param = {
            Language,
            FormID,
            EmployeeID,
            ApprovalStatus: _.toString(AppStatusID),
            VoucherID,
            DateFrom,
            DateTo,
            skip,
            limit
        };
        setGridLoading(true);
        props.w09f2280Actions.getDataGrid(param, (error) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboEmployees = (isReset) => {
        const { skip, limit } = filterCboEmployees.current;
        const param = {
            skip,
            limit,
            FormID,
            Language,
            Type: "EmployeeID",
        };
        setCboEmployeesLoading(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
            setCboEmployeesLoading(false);
            if (error) {
                Config.popup.show("ERROR", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            }
            else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total
                });
            }
        });
    };

    const loadCboAppStatus = () => {
        const param = {
            FormID: "STANDARD",
            Language
        };
        setCboAppStatusLoading(true);
        props.generalActions.getCboAppStatus(param, error => {
            setCboAppStatusLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const onChangePage = (page) => {
        dataFilterGrid.current.skip = page * dataFilterGrid.current.limit;
        loadDataGrid();
    };

    const onChangePerPage = (perPage) => {
        dataFilterGrid.current.skip = 0;
        dataFilterGrid.current.limit = perPage;
        loadDataGrid();
    };

    const renderEmpProfile = (e) => {
        const { data } = e.row;
        const dataCreateUserID = Config.getUser({ UserID: _.get(data, "CreateUserID", "") });
        const date = moment.utc(data.CreateDate).isValid() ?
            moment.utc(data.CreateDate).format("LT, DD/MM/YYYY") : "";
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div><UserImage data={dataCreateUserID} /> </div>
                <div className={"w09f2280_info"}>
                    <div className={classes.divText}>
                        <UserName data={dataCreateUserID} />
                    </div>
                    <div className={classes.divText}>
                        {_.get(dataCreateUserID, "DepartmentName", "")}
                    </div>
                    <div className={classes.dateInfo}>
                        <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                        <div style={{ paddingLeft: 8 }} className={"date-text-info"}>
                            {data.CreateDate && date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderInfo = (e) => {
        const { data } = e.row;
        const content = <div className={classes.VoucherNo} dangerouslySetInnerHTML={{ __html: data.VoucherNo }} />;
        return <Tooltip title={content}>{content}</Tooltip>;
    };

    const renderStatus = e => {
        const { data } = e.row;
        return (
            <Status data={data} />
        );
    };

    const onAdd = () => {
        setShowW09F2281({
            mode: 'add'
        })
    };

    const onEdit = (data = {}) => {
        if (_.isEmpty(data)) return;
        currentRowClick.current = data;
        setShowW09F2281({
            mode: "edit",
            data
        });
    };

    const onView = (data = {}) => {
        if (_.isEmpty(data)) return;
        currentRowClick.current = data;
        setShowW09F2281({
            mode: "view",
            data
        });
    };

    const onHistoryApproval = (data = {}) => {
        if (_.isEmpty(data)) return;
        currentRowClick.current = data;
        setShowW84F3005Popup(true);
    };

    const onDelete = async (dataDel) => {
        const param = {
            VoucherID: _.get(dataDel, "VoucherID", "")
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            props.w09f2280Actions.deleteRow(param, async (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (_.get(data, "Status", 1) === 0) {
                    const statusSaveHistory = await onSaveHistory(dataDel);
                    if (statusSaveHistory) {
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                        loadDataGrid();
                    } else {
                        Config.popup.show("ERROR", Config.lang("Loi_chua_xac_dinh"));
                    }
                }
            });
        });
    };

    const onSaveHistory = async (data = {}, dataCompare = {}, action = 3) => { // default xóa
        if (_.isEmpty(data)) return;
        let status = false;
        const TransactionName = Config.lang("Khen_thuong_hang_loat");
        const TransID = _.get(data, "VoucherID", "");
        const captions = {
            VoucherName: "Ten_phieu",
            Notes: "Ghi_chu",
        };
        if (!_.isEmpty(dataCompare)) action = 1; // Lịch sử Cập nhập trạng Thái
        const options = {
            data,
            dataCompare, // Old Data
            captions,
            action,
            ModuleID: "D09",
            TransactionID: FormID,
            TransID,
            TransactionName
        };
        const history = new History(options);
        if (history.get()?.length > 0) {
            if (await history.save()) {
                status = true;
            }
        }
        return status;
    };

    const renderAction = (e) => {
        const data = _.get(e, "row.data", {});
        return (
            <div className={"display_row align-center"}>
                <Tooltip title={Config.lang("Lich_su_duyet")} >
                    <ButtonIcon
                        circular
                        viewBox
                        name={"History"}
                        aria-label={"history"}
                        viewType={"text"}
                        disabled={!(iPermission > 0)}
                        onClick={() => onHistoryApproval(data)}
                        style={{ cursor: 'pointer' }} />
                </Tooltip>
                <Tooltip title={Config.lang("Sua_du_lieu")} >
                    <ButtonIcon
                        circular
                        viewBox
                        name={"View"}
                        aria-label={"view"}
                        viewType={"text"}
                        disabled={!(iPermission > 2) || (data.AppStatusID ? (_.toNumber(data.AppStatusID) !== 0) : true)}
                        onClick={() => onEdit(data)}
                        style={{ cursor: 'pointer' }} />
                </Tooltip>
                <Tooltip title={Config.lang("Xoa")} >
                    <ButtonIcon
                        circular
                        viewBox
                        name={"Delete"}
                        aria-label={"delete"}
                        viewType={"text"}
                        disabled={!(iPermission > 3) || (data.AppStatusID ? (_.toNumber(data.AppStatusID) !== 0) : true)}
                        onClick={() => onDelete(data)}
                        style={{ cursor: 'pointer' }} />
                </Tooltip>
            </div>
        );
    };

    const filterChange = (filterName = "", e) => {
        if (!e || _.isEmpty(filterName)) return;
        let value = _.get(e, "value", ""); // DD và DateTime
        switch (filterName) {
            case "DateFrom":
            case "DateTo":
                value = _.get(e, "value", null);
                break;
            default:
                break;
        }
        setDataFilter({
            ...dataFilter,
            [filterName]: value
        });
    };

    const onLoadCboFilter = () => {
        if (_.isEmpty(getCboAppStatus)) loadCboAppStatus();
        if (_.isEmpty(_.get(dataCboEmployees, "rows", []))) loadCboEmployees();
    };

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={true}
                isUseDDCore={true}
                openOnFieldClick={false}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6} >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Label style={{ paddingBottom: 15, minWidth: 22 }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                        <DatePicker
                                            clearAble
                                            width={"100%"}
                                            margin={"normal"}
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            max={dataFilter.DateTo}
                                            value={dataFilter.DateFrom}
                                            onChange={e => filterChange("DateFrom", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Label style={{ paddingBottom: 15, minWidth: 22 }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                        <DatePicker
                                            clearAble
                                            width={"100%"}
                                            margin={"normal"}
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            min={dataFilter.DateFrom}
                                            value={dataFilter.DateTo}
                                            onChange={e => filterChange("DateTo", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        clearAble
                                        viewType={"outlined"}
                                        valueExpr={"AppStatusID"}
                                        keyExpr={"AppStatusName"}
                                        displayExpr={"AppStatusName"}
                                        placeholder={Config.lang("Chon")}
                                        label={Config.lang("Trang_thai_duyet")}
                                        onChange={e => filterChange("AppStatusID", e)}
                                        loading={cboAppStatusLoading}
                                        dataSource={getCboAppStatus}
                                        value={dataFilter.AppStatusID}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        clearAble
                                        dataSource={_.get(dataCboEmployees, "rows", [])}
                                        total={_.get(dataCboEmployees, "total", 0)}
                                        skip={filterCboEmployees.current.skip}
                                        limit={filterCboEmployees.current.limit}
                                        value={dataFilter.EmployeeID}
                                        loading={cboEmployeesLoading}
                                        viewType={"outlined"}
                                        valueExpr={"EmployeeID"}
                                        keyExpr={"EmployeeName"}
                                        treeViewID={"EmployeeID"}
                                        treeViewParentID={"EmployeeName"}
                                        label={Config.lang("Nhan_vien")}
                                        placeholder={Config.lang("Chon")}
                                        displayExpr={"{EmployeeID} - {EmployeeName}"}
                                        onChange={(e) => filterChange("EmployeeID", e)}
                                        onLoadMore={(e) => {
                                            filterCboEmployees.current.skip = e.skip;
                                            filterCboEmployees.current.limit = e.limit;
                                            loadCboEmployees();
                                        }}
                                    />
                                </Col>
                            </Row>
                            <div style={{ display: "flex", justifyContent: "center" }}  >
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const onCloseW09F2281 = (reload = true) => {
        setShowW09F2281(null);
        if (reload) loadDataGrid();
    };

    if (!iPermission) return null;
    return (
        <>
            {!!showW09F2281 &&
                <W09F2281 open={!!showW09F2281}
                    iPermission={iPermission}
                    mode={showW09F2281?.mode}
                    data={showW09F2281?.data}
                    onClose={onCloseW09F2281}
                />
            }
            <W84F3005 open={showW84F3005Popup} onClose={() => setShowW84F3005Popup(false)}
                FormID={FormID} TransID={currentRowClick.current.VoucherID} />
            <FormGroup>
                <div className={"hidden"}>{renderFilter()}</div>
                <ActionToolbar title={Config.lang("Khen_thuong_hang_loat")}>
                    <Button
                        disabled={!(iPermission > 1)}
                        size={"medium"}
                        color={"primary"}
                        viewType={"filled"}
                        label={Config.lang("Them")}
                        style={{ textTransform: 'uppercase' }}
                        startIcon={"AddCircle"}
                        onClick={onAdd}
                    />
                </ActionToolbar>
            </FormGroup>
            <GridContainer
                style={{ border: "none" }}
                dataSource={_.get(dataGrid, "rows", [])}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                noDataText={Config.lang("No_data")}
                loading={gridLoading}
                hoverStateEnabled={true}
                totalItems={(dataGrid && Number(dataGrid.total)) || 0}
                itemPerPage={dataFilterGrid.current.limit}
                skipPerPage={dataFilterGrid.current.skip}
                typePaging={"remote"}
                onRowDblClick={(e) => {
                    onView(e?.data);
                }}
                height={Config.getHeightGrid()}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
            >
                <Column cellRender={renderEmpProfile} width={300} />
                <Column cellRender={renderInfo}/>
                <Column cellRender={renderStatus} alignment={"center"} minWidth={180} />
                <Column cellRender={renderAction} width={180} />
            </GridContainer>
        </>
    );
};

export default compose(
    connect(state => ({
        getCboAppStatus: state.general.getCboAppStatus,
        dataGrid: state.W09F2280.dataGrid
    }), dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w09f2280Actions: bindActionCreators(W09F2280Actions, dispatch)
    })))(W09F2280);
